import React, { useState, useEffect, useContext} from 'react';
import moment from 'moment';
import { FirebaseContext } from '../../Firebase';
import { MixpanelContext } from '../../Mixpanel';
import PageHeader from '../../PageHeader/PageHeader';
import PageFooter from '../../PageFooter/PageFooter';
import ContentContainer from '../../ContentContainer/ContentContainer';
import PrimaryButtonNoLoading from '../../Buttons/PrimaryButton/PrimaryButtonNoLoading';
import UnitAddPage from '../UnitAddPage';
import ErrorDialog from '../../Dialogs/ErrorDialog'
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import CardHeader from '@material-ui/core/CardHeader';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddTenantPage from '../AddTenantPage/AddTenantPage';

const DashboardPage = ({ match }) => {
    const [property, setProperty] = useState({});
    const [addUnit, setAddUnit] = useState(false);
    const [addLead, setAddLead] = useState(false);
    const [error, setError] = useState(false);
    const [units, setUnits] = useState([]);
    const [unit, setUnit] = useState(getDefaultUnit());
    const [unitToBeDeleted, setUnitToBeDeleted] = useState();
    const [leadFormError, setLeadFormError] = useState({});
    const [sending, setSending] = useState(false);
    const firebase = useContext(FirebaseContext);
    const db = firebase.db;
    const mixpanel = useContext(MixpanelContext);
    const propertyId = match.params.id;

    function getDefaultUnit() {
        return {
            number: '',
            bedrooms: 0,
            bathrooms: 0,
            squareFootage: 0,
            deposits: [],
            fees: [],
            rents: [],
            availableDate: moment().format('YYYY-MM-DD')
        };
    }

    useEffect(() => {
        db.doc(`properties/${propertyId}`).get()
        .then(doc => {
            if (doc.exists) {
                setProperty(doc.data());
            }
        }).catch(err => {
            console.error(err);
            setError(true);
        });

        db.collection(`properties/${propertyId}/units`)
        .onSnapshot(snapshot => {
            setUnits(snapshot.docs.map(doc => {
                const unit = doc.data();
                unit.id = doc.id;
                return unit;
            }));
        });

        mixpanel.identify(propertyId);
        mixpanel.track("Page Load");
    }, [db, mixpanel, propertyId]);


    function handleAddUnitClick() {
        mixpanel.track('Click', {
            button: 'add unit'
        });
        setAddUnit(true);
    }

    async function saveOrUpdate(unit) {
        if (unit.id) {
            const unitToUpdate = {...unit};
            delete unitToUpdate.id;
            await db.collection(`properties/${propertyId}/units`).doc(unit.id).update(unitToUpdate);
            mixpanel.track('unit updated', {
                id: unit.id
            });
        } else {
            const ref = await db.collection(`properties/${propertyId}/units`).add(unit);
            mixpanel.track('unit created', {
                id: ref.id
            });
        }
    }

    function handleUnitSave(unit) {
        setAddUnit(false);
        try {
            saveOrUpdate(unit)
        } catch(err) {
            console.error(err);
            setError(true);
        }
        setUnit(getDefaultUnit());
    }

    function handleUnitCancel() {
        mixpanel.track('Click', {
            button: 'unit add cancel',
            id: unit.id
        });
        setUnit(getDefaultUnit());
        setAddUnit(false);
    }

    function handleDeleteUnit(id) {
        mixpanel.track('Click', {
            button: 'delete unit',
            id: id
        });
        setUnitToBeDeleted(id);
    }

    function handleEditUnit(unit) {
        mixpanel.track('Click', {
            button: 'edit unit',
            id: unit.id
        });
        setUnit(unit); 
        setAddUnit(true);
    }

    function handleCancelDelete() {
        mixpanel.track('Click', {
            button: 'cancel delete'
        });
        setUnitToBeDeleted(null);
    }

    function handleConfirmDelete() {
        db.doc(`properties/${propertyId}/units/${unitToBeDeleted}`).delete()
        .then(() => {
            mixpanel.track('unit deleted', {
                id: unitToBeDeleted
            });
            setUnitToBeDeleted(null);
        });
    }

    function handleSendBillClick(unit) {
        mixpanel.track('Click', {
            button: 'send bill'
        });
        setUnit(unit);
        setAddLead('bill');
    }

    function onAddLeadCancel(type) {
        mixpanel.track('Click', {
            button: `cancel sending ${type}`
        });
        setUnit(getDefaultUnit());
        setAddLead(false);
        setLeadFormError({});
    }

    function onCreateLead(lead, type) {
        if (validateLeadForm(lead, type)) {
            setSending(true);
            const leadDoc = {};
            // Property Data
            leadDoc.propertyName = property.propertyName;
            leadDoc.propertyId = propertyId;
            leadDoc.propertyImage = property.propertyImage;
            leadDoc.address = property.address;

            // Coverage
            const coverage = {};
            coverage.rents = unit.rents;
            coverage.fees = unit.fees;
            coverage.deposits = unit.deposits;
            leadDoc.coverage = coverage;

            // Tenant
            const tenant = {};
            tenant.firstName = lead.firstName;
            tenant.lastName = lead.lastName;
            tenant.phone = lead.phone;
            tenant.email = lead.email;
            leadDoc.tenant = tenant;

            // Lease
            const lease = {};
            lease.dueDate = lead.dueDate;
            lease.moveinDate = lead.moveinDate;
            lease.term = lead.term;
            leadDoc.lease = lease;

            // Unit
            const unitData = {};
            unitData.bathrooms = unit.bathrooms;
            unitData.bedrooms = unit.bedrooms;
            unitData.availableDate = unit.availableDate;
            unitData.number = unit.number;
            unitData.squareFootage = unit.squareFootage;
            leadDoc.unit = unitData;

            // Update address with the unit number
            leadDoc.address.address2 = unit.number

            const createLead = firebase.funcs.httpsCallable('createLeadFunction');
            const leadStatus = type === 'bill' ? 'inbound' : 'tourable';
            createLead({
                lead: leadDoc,
                initialStatus: leadStatus
            }).then(res => {
                const leadId = res.data;
                mixpanel.track('Lead Created', {
                    id: leadId
                });
                if (type === 'bill') {
                    const sendLinkToTenant = firebase.funcs.httpsCallable('sendApplicationLink');
                    sendLinkToTenant({
                        leadId: leadId
                    }).then(() => {
                        mixpanel.track('Lead Contacted', {
                            id: leadId,
                            phone: lead.phone
                        });
                    });
                } else if (type === 'tour') {
                    const createAndSendTour = firebase.funcs.httpsCallable('createAndSendTour');
                    createAndSendTour({
                        leadId: leadId,
                        to: lead.phone
                    }).then(tourId => {
                        mixpanel.track('Tour Started', {
                            phone: lead.phone,
                            id: tourId
                        });
                    });
                    // Not waiting on the tour to be created since it takes a while to create
                    // the screenshot, so just close the modal when the call is made, i.e. here.
                    setUnit(getDefaultUnit());
                }
                setAddLead(false);
                setSending(false);
            }).catch(err => {
                console.error(err);
                setError(true);
            });
        }
    }

    function validateLeadForm(lead, type) {
        const error = {};
        let valid = true;
        if (!lead.firstName) {
            error.firstName = true;
            valid = false;
        }

        if (!lead.lastName) {
            error.lastName = true;
            valid = false;
        }

        if (!lead.phone || lead.phone.length !== 10) {
            error.phone = true;
            valid = false;
        }

        if (!lead.term && type === 'bill') {
            error.term = true;
            valid = false;
        }
        setLeadFormError(error);
        return valid;
    }

    function handleSendTour(unit, property) {
        mixpanel.track('Click', {
            button: 'send tour'
        });
        setUnit(unit);
        setAddLead('tour');
    } 

    const unitElements = [];

    units.forEach((unit, index) => {
        unitElements.push(
            <Card style={{marginTop: '20px', marginBottom: '20px'}}key={index}>
                <CardHeader title={`Unit ${unit.number}`} />
                <CardActions>
                    <Button size="small" color="primary" onClick={() => handleSendBillClick(unit)}>
                        Send Bill
                    </Button>
                    <Button size="small" color="primary" onClick={() => handleSendTour(unit, property)}>
                        Send Tour
                    </Button>
                    <IconButton size="small" color="primary" onClick={() => handleEditUnit(unit)} >
                        <EditIcon />
                    </IconButton>
                    <IconButton size="small" color="primary" onClick={() => handleDeleteUnit(unit.id)} >
                        <DeleteIcon />
                    </IconButton>
                </CardActions>
            </Card>
        );
    });

    let pageContent = (
        <>
            <PageHeader>{property.propertyName}</PageHeader>
                <ContentContainer>
                    { unitElements }
                    <PrimaryButtonNoLoading onClick={handleAddUnitClick}>Add Unit</PrimaryButtonNoLoading>
                </ContentContainer>
                <ErrorDialog open={error} handleClose={() => setError(false)} />
                <Dialog open={!!unitToBeDeleted} onClose={()=>{}}>
                    <DialogTitle>{"Are you sure you want to delete the unit?"}</DialogTitle>
                    <DialogActions>
                        <Button onClick={handleCancelDelete} color="primary" autoFocus>
                            cancel
                        </Button>
                        <Button onClick={handleConfirmDelete} color="primary">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            <PageFooter />
        </>
    );

    if (addUnit) {
        pageContent = <UnitAddPage propertyName={property.propertyName} newUnit={unit} onSave={handleUnitSave} onCancel={handleUnitCancel}/>;
    } else if (addLead) {
        pageContent = <AddTenantPage type={addLead} propertyName={property.propertyName} onSave={onCreateLead} onCancel={onAddLeadCancel} error={leadFormError}  sending={sending}/>
    }

    return pageContent;
}

export default DashboardPage;