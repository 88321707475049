import React from 'react';
import styles from './ConnectedAccounts.module.css';
import { PageTitle, PageSubTitle } from '../Titles/Titles';
import AccountCard from './AccountCard/AccountCard';
import PlaidLink from '../Plaid/Link/Link';

const ConnectedAccounts = ({ checkingAccounts, creditAccounts, hiwButton, onPlaidSuccess, onPlaidExit, product, dueToday }) => {
    const traditional = product === 'traditional';
    let pageTitle = "Connect your accounts";
    let pageSubtitle = <span>Connect one checking account and one credit card to Rentline {hiwButton}</span>;
    let checkingTitle = "Select checking account";
    let creditCardBlock = (
        <>
            <div className={styles.divider} />
            
            <div className={styles.subheading}>Select credit card</div>
            {
                creditAccounts.map((creditAccount, index) => {
                    return (
                        <AccountCard 
                            key={index}
                            institution={creditAccount.institution}
                            name={creditAccount.name}
                            type={creditAccount.type}
                            mask={creditAccount.mask}
                            selected={creditAccount.selected}
                            onSelect={creditAccount.onSelect}
                        />
                    )
                })
            }
            <PlaidLink mini onSuccess={onPlaidSuccess} onExit={onPlaidExit}> Add a different card </PlaidLink>
        </>
    );
    if (traditional) {
        pageTitle = "Select payment method";
        pageSubtitle = `Select a checking or savings account to use to pay the move-in bill of ${dueToday}.`;
        checkingTitle = "Select bank account";
        creditCardBlock = null;
    }
    return (
        <>
                <PageTitle>{pageTitle}</PageTitle>
                <PageSubTitle>{pageSubtitle}</PageSubTitle>
                <div className={styles.subheading}>{checkingTitle}</div>
                {
                    checkingAccounts.map((checkingAccount, index) => {
                        return (
                            <AccountCard 
                                key={index}
                                institution={checkingAccount.institution}
                                name={checkingAccount.name}
                                type={checkingAccount.subtype}
                                mask={checkingAccount.mask}
                                selected={checkingAccount.selected}
                                onSelect={checkingAccount.onSelect}
                            />
                        )
                    })
                }

                <PlaidLink mini onSuccess={onPlaidSuccess} onExit={onPlaidExit}> Add a different bank</PlaidLink>
                
                {
                    creditCardBlock
                }
         </>
    );
}

export default ConnectedAccounts;