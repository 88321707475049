import React, { useState } from 'react';
import moment from 'moment';
import PageHeader from '../../PageHeader';
import ContentContainer from '../../ContentContainer';
import PageFooter from '../../PageFooter';
import Input from '../../Forms/Fields/Input';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DatePicker } from '@material-ui/pickers';
import InputMask from "react-input-mask";
import Divider from '@material-ui/core/Divider';
import PrimaryButtonNoLoading from '../../Buttons/PrimaryButton/PrimaryButtonNoLoading';

const AddTenantPage = ({ propertyName, onSave, onCancel, error, type, sending }) => {
    const [lead, setLead] = useState({
        moveinDate: moment().format('YYYY-MM-DD'),
        dueDate: moment().format('YYYY-MM-DD')
    });

    function handleOnChange(event) {
        const name = event.target.name;
        let value = event.target.value;
        if (name === 'phone') {
            value = value.replace(/\D/g, '');
        }
        setLead(oldLead => {
            const newLead = {...oldLead};
            newLead[name] = value;
            return newLead;
        });
    }

    function handleDatePicked(date, input) {
        setLead(oldLead => {
            const newLead = {...oldLead};
            newLead[input] = date.format('YYYY-MM-DD');
            return newLead;
        });
    }

    function handleDone() {
        onSave(lead, type);
    }

    const sendButtonText = `Send ${type === 'bill' ? 'Bill' : 'Tour'}`;
    let leaseForm = null;
    if (type === 'bill') {
        leaseForm = ( 
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Divider />
                <Input name="term" label="Lease Duration" required type="number" helperText="in months" onChange={handleOnChange} error={error.term}/>
                <DatePicker name="moveinDate" label="Move-In Date" format={'YYYY-MM-DD'} style={{margin: '16px', width: '40%'}} inputVariant='outlined' onChange={(date)=> handleDatePicked(date, 'moveinDate')} value={lead.moveinDate}/> 
                <DatePicker name="dueDate" label="Due Date" format={'YYYY-MM-DD'} style={{margin: '16px', width: '40%'}} inputVariant='outlined' onChange={(date)=> handleDatePicked(date, 'dueDate')} value={lead.dueDate}/> 
            </MuiPickersUtilsProvider>);
    }
    return (<>
        <PageHeader>{propertyName}</PageHeader>
        <ContentContainer>
            <Input name="firstName" label="First Name" autoFocus  required onChange={handleOnChange} error={error.firstName}/>
            <Input name="lastName" label="Last Name" required onChange={handleOnChange} error={error.lastName}/>
            <InputMask name="phone" mask="(999) 999-9999" maskChar=" " onChange={handleOnChange} error={error.phone}>
                <Input  type="tel" label="Phone Number" required />
            </InputMask>
            <Input name="email" label="Email" type="email" onChange={handleOnChange} error={error.email}/>
            {leaseForm}
            <PrimaryButtonNoLoading loading={sending} disabled={sending}  onClick={handleDone}>{sendButtonText}</PrimaryButtonNoLoading>
            <PrimaryButtonNoLoading onClick={() => onCancel(type)}>Cancel</PrimaryButtonNoLoading>
        </ContentContainer>
        <PageFooter />
    </>);
}

export default AddTenantPage;