import React, { useContext, useState, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { FirebaseContext } from '../Firebase';
import { MixpanelContext } from '../Mixpanel';
import { toWholeDollars } from '../Helpers';
import { mergeAndDedupe, sumFees, getRows, getFeeRow, getSavingsString, calculateRentlineFee } from './Helpers';
import TenantEnrollmentPage from './TenantEnrollmentPage';
import DonePage from '../DonePage';
import PaymentReviewPage from '../PaymentReviewPage/PaymentReveiwPage';
import HowItWorksPage from '../HowItWorksPage';
import PageHeader from '../PageHeader/PageHeader';
import PageFooter from '../PageFooter/PageFooter';
import PrimaryButton from '../Buttons/PrimaryButton/PrimaryButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import ScreenshotableFeeForm from '../FeeForm/ScreenshotableFeeForm';
import ErrorDialog from '../Dialogs/ErrorDialog';

const TenantEnrollmentContainer = ({ match, history }) => {
    const firebase = useContext(FirebaseContext);
    const mixpanel = useContext(MixpanelContext);
    const [coverage, setCoverage] = useState();
    const [propertyName, setPropertyName] = useState("");
    const [propertyId, setPropertyId] = useState("");
    const [moveInDate, setMoveInDate] = useState();
    const [rows, setRows] = useState([]);
    const [choice, setChoice] = useState('rentline');
    const [checkingAccounts, setCheckingAccounts] = useState([]);
    const [creditCardAccounts, setCreditCardAccounts] = useState([]);
    const [checkingAccount, setCheckingAccount] = useState();
    const [creditAccount, setCreditAccount] = useState();
    const [securityDeposit, setSecurityDeposit] = useState(0);
    const [rentlineFee, setRentlineTotalAmount] = useState(0);
    const [traditionalTotalAmount, setTraditionalTotalAmount] = useState(0);
    const [tenant, setTenant] = useState();
    const [plaidResponses, setPlaidResponses] = useState([]);
    const [savings, setSavings] = useState();
    const [leadStatus, setLeadStatus] = useState('contacted');
    const [error, setError] = useState(false);

    /*
    *  Fetch the lead data from Firestore.
    */
    useEffect(() => {
        const leadRef = firebase.db.doc(`leads/${match.params.id}`);
        leadRef.get().then(doc => {
            if (doc.exists) {
                const data = doc.data();
                const coverage = data.coverage;
                const fees = coverage.fees;
                const rents = coverage.rents;
                const feeTotal = sumFees(fees) + sumFees(rents);
                const securityDeposit = parseFloat(data.coverage.amount);
                const rentlineFee = calculateRentlineFee(securityDeposit);
                const traditionalTotalAmount = feeTotal + securityDeposit;
                const rentlineTotalAmount = feeTotal + rentlineFee;
                const savingsString = getSavingsString(traditionalTotalAmount, rentlineTotalAmount);                
                
                let feeRows = getRows(coverage, getFeeRow("Rentline fee", "non-refundable", "-", toWholeDollars(rentlineFee)));
                feeRows.push(getFeeRow("You pay today", "", toWholeDollars(traditionalTotalAmount), toWholeDollars(rentlineTotalAmount), true, savingsString));
                setSavings(savingsString);
                setRows(feeRows);
                setTenant(data.tenant);
                setMoveInDate(data.lease.moveinDate.toDate().toDateString());
                setCoverage(coverage);
                setSecurityDeposit(securityDeposit);
                setPropertyName(data.propertyName);
                setPropertyId(data.propertyId);
                setRentlineTotalAmount(rentlineTotalAmount);
                setTraditionalTotalAmount(traditionalTotalAmount);
                setLeadStatus(doc.data().status);

                mixpanel.identify(data.tenant.phone);
                mixpanel.people.set({
                    $name: `${data.tenant.firstName} ${data.tenant.lastName}`,
                    $email: data.tenant.email
                });
                mixpanel.register({
                    lead: match.params.id,
                    property: data.propertyName
                });
                mixpanel.track("Page Load");
            } else {
                history.push('/not-found');
            }
        });
    }, [firebase, match, history, mixpanel]);

    /*
    *   handles deposit option selection
    */
    function onChoose(choice) {
        const options = {};
        if (choice === 'left') {
            options.choice = 'traditional';
            setChoice('traditional');
        } else {
            options.choice = 'rentline';
            setChoice('rentline');
        }
        mixpanel.register({
            choice: options.choice
        });
        mixpanel.track('Deposit Choice', options);
    }

    /*
    *   Get Accounts from plaid response based on type + subtype
    */
    function getAccountsByType(plaidResponse, publicToken, type, subtype) {
        const institution = plaidResponse.institution.name;
        const institutionId = plaidResponse.institution.institution_id;
        const allAccounts = plaidResponse.accounts;
        const filteredAccounts = allAccounts.filter(account => account.type === type && account.subtype === subtype);
        return filteredAccounts.map(account => {
            const newAccount = {...account};
            newAccount.institution = institution;
            newAccount.institutionId = institutionId;
            newAccount.selected = false;
            newAccount.publicToken = publicToken;
            newAccount.onSelect = (selected) => {
                if (newAccount.type === 'depository') {
                    handleSelectedChecking(newAccount.id, publicToken, selected, newAccount.mask);
                }

                if (account.subtype === 'credit card') {
                    handleSelectedCredit(newAccount.id, publicToken, selected);
                }
            }
            return newAccount;
        });
    }

    function handleSelectedCredit(accountId, publicToken, selected) {
        if (selected) {
            setCreditAccount({
                id: accountId,
                token: publicToken
            });
        } else {
            setCreditAccount(null);
        }
        setCreditCardAccounts(accounts => {
            const newAccounts = [...accounts];
            newAccounts.map(account => {
                if (account.id === accountId) {
                    account.selected = selected;
                } else {
                    account.selected = false;
                }
                return account;
            });
            return newAccounts;
        });
    }

    function handleSelectedChecking(accountId, publicToken, selected, mask) {
        if (selected) {
            setCheckingAccount({
                id: accountId,
                token: publicToken,
                mask: mask
            });
        } else {
            setCheckingAccount(null);
        }
        setCheckingAccounts(accounts => {
            const newAccounts = [...accounts];
            newAccounts.map(account => {
                if (account.id === accountId) {
                    account.selected = selected;
                } else {
                    account.selected = false;
                }
                return account;
            });
            return newAccounts;
        });
    }

    function onPlaidSuccess(publicToken, metadata) {
        setPlaidResponses(responses => {
            const newResponses = [...responses];
            newResponses.push(metadata);
            return newResponses;
        });

        setCheckingAccounts(accounts => {
            const depositoryAccounts = mergeAndDedupe(
                            getAccountsByType(metadata, publicToken, 'depository', 'checking'),
                            getAccountsByType(metadata, publicToken, 'depository', 'savings'));
            return mergeAndDedupe(depositoryAccounts, accounts);
        });

        setCreditCardAccounts(accounts => mergeAndDedupe(
                                getAccountsByType(metadata, publicToken, 'credit', 'credit card'),
                                accounts));
    }

    function onPlaidExit(err, metadata) {}

    function getReviewRows() {
        const rows = [];

        if (coverage) {
            if (choice === 'traditional') {
                if (coverage.deposits) {
                    coverage.deposits.forEach((deposit) => {
                        rows.push(getFeeRow(deposit.name, "", toWholeDollars(deposit.amount)));
                    }); 
                }
            } else {
                const rentlineFee = calculateRentlineFee(securityDeposit);
                const rentlineFeeString = toWholeDollars(rentlineFee);
                rows.push(getFeeRow("Rentline fee", "", rentlineFeeString));
            }
            
            if (coverage.fees) {
                coverage.fees.forEach((fee) => {
                    rows.push(getFeeRow(fee.name, "", toWholeDollars(fee.amount)));
                });
            }

            if (coverage.rents) {
                coverage.rents.forEach((rent) => {
                    rows.push(getFeeRow(rent.name, "", toWholeDollars(rent.amount)));
                });
            }

            rows.push(getFeeRow("Total move-in expenses", "", toWholeDollars(getTotalFees())));
        }

        return rows;
    }

    function getTotalFees() {
        if (choice === 'traditional') {
            return traditionalTotalAmount;
        } else {
            return rentlineFee;
        }
    }
 
    function handlePaymentAuthorization() {
        let rentlineFee = calculateRentlineFee(securityDeposit);
        if (choice === 'traditional') {
            rentlineFee = 0;
        }

        const processPayment = firebase.funcs.httpsCallable('processPayment');
        processPayment({
            customerId: tenant.id,
            amount: getTotalFees(),
            rentlineFee: rentlineFee,
            leadId: match.params.id,
            propertyId: propertyId
        }).then(() => {
            const leadRef = firebase.db.doc(`leads/${match.params.id}`);
            return leadRef.update({status: 'processed'});
        }).then(() => { 
            history.push(`${match.url}/done`);
        }).catch(err => {
            console.log(err);
            setError(true);
        });

    }

    function handleOnReadyForReview() {
        const handlePlaidResponses = firebase.funcs.httpsCallable('handlePlaidResponses');
        handlePlaidResponses({
            plaidResponses: plaidResponses,
            checkingAccount: checkingAccount,
            creditAccount: creditAccount,
            customer: tenant
        }).then(() => {
            history.push(`${match.url}/review`);
        }).catch(err => {
            console.log(err);
            setError(true);
        });
    }

    let redirectLink = <Redirect path={`${match.url}`} exact to={`${match.url}/product`} />;
    if (leadStatus === 'inbound') {
        redirectLink = <Redirect to="/not-found" />;
    }

    return (<>
        <Switch>
            {redirectLink}
            <Route strict path={`${match.url}/product`} render={() =>
                leadStatus === "contacted" ?
                <TenantEnrollmentPage 
                        rows={rows} 
                        propertyName={propertyName} 
                        coverageAmount={toWholeDollars(securityDeposit)} 
                        onChoose={onChoose}
                        choice={choice === 'rentline' ? 'right' : 'left'}
                        checkingAccounts={checkingAccounts}
                        creditAccounts={creditCardAccounts}
                        onPlaidExit={onPlaidExit}
                        onPlaidSuccess={onPlaidSuccess}
                        checkingAccount={checkingAccount}
                        creditCardAccount={creditAccount}
                        onReadyForReview={handleOnReadyForReview}
                        activeStep={match.params.step}
                        dueToday={toWholeDollars(getTotalFees())}
                        product={choice}
                        savings={savings}
                        history={history}
                        match={match}
                    /> :
                <Redirect to={`${match.url}/done`} />
            } />
            <Route exact path={`${match.url}/review`} render={() =>
                leadStatus === "contacted" ?
                <PaymentReviewPage 
                    property={propertyName}
                    title="Pay move-in bill"
                    subtitle={`After you authorize payment below, we will notify ${propertyName} that you’re all paid up.`}
                    rows={getReviewRows(coverage)}
                    amount={securityDeposit}
                    onAuthorize={handlePaymentAuthorization}
                    choice={choice}
                    product={choice}
                    account={checkingAccount}
                    dueToday={toWholeDollars(getTotalFees())} /> :
                <Redirect to={`${match.url}/done`} />
            } />
            <Route exact path={`${match.url}/done`} render={() => 
                <DonePage 
                    property={propertyName}
                    product={choice}
                    amount={toWholeDollars(getTotalFees())}
                    coverage={toWholeDollars(securityDeposit)}
                    date={moveInDate}
                    mask={checkingAccount ? checkingAccount.mask : ''}/>
            } />
            <Route exact path={`${match.url}/how-it-works`} render={() => <>
                <HowItWorksStandAlone 
                    propertyName={propertyName}
                    securityDeposit={securityDeposit}
                    mixpanel={mixpanel}
                    history={history}
                    setChoice={setChoice}
                />
            </>} />
            <Route exact path={`${match.url}/screenshot`} render={() => <>
                <ScreenshotableFeeForm 
                    savings={savings}
                    rows={rows}
                />
            </>} />
        </Switch>
        <ErrorDialog open={error} handleClose={() => setError(false)} />
    </>);
}

const HowItWorksStandAlone = ({ propertyName, securityDeposit, mixpanel, history, setChoice }) => {
    const state = history.location.state;
    let button = (
        <PrimaryButton onClick={() => {
            mixpanel.track('Click', {
                button: 'back'
            });
            history.go(-1);
        }}>
            <FontAwesomeIcon icon={faArrowAltCircleLeft} /> Back
        </PrimaryButton>
    );

    if (state && state.upsell) {
        button = (<>
            <PrimaryButton onClick={() => {
                mixpanel.track('Click', {
                    button: 'change to rentline'
                });
                setChoice('rentline');
                history.replace(state.url);
                }}>
                    Save with Rentline
            </PrimaryButton>
            <PrimaryButton onClick={() => {
                mixpanel.track('Click', {
                    button: 'back to pre-paid'
                });
                history.go(-1);
             }}>
                 Return to Pre-paid
            </PrimaryButton>
        </>);
    }

    return (<>
        <PageHeader>{propertyName}</PageHeader>
        <HowItWorksPage 
            property={propertyName} 
            amount={toWholeDollars(securityDeposit)}
            button={button}
        />
        <PageFooter />
    </>);
}

export default TenantEnrollmentContainer;