import React from 'react';
import app from 'firebase/app';
import { ENVS } from '../Constants'
import 'firebase/auth';
import 'firebase/firebase-firestore';
import 'firebase/functions';
import firebaseConfig from './FirebaseConfig';

/*
    Handles Firebase interface.
*/
class Firebase {
    constructor() {
        app.initializeApp(firebaseConfig(process.env.REACT_APP_RENTLINE_APP_ENV));
        this.auth = app.auth();
        this.db = app.firestore();
        this.funcs = app.functions();
        if (process.env.REACT_APP_RENTLINE_APP_ENV === ENVS.LOCAL) {
            this.funcs.useFunctionsEmulator('http://localhost:5001');
        }
    }

    async login(email, password) {
        return await this.auth.signInWithEmailAndPassword(email, password);
    }

    logout() {
        return this.auth.signOut();
    }

    sendPasswordResetEmail(emailAddress) {
        return this.auth.sendPasswordResetEmail(emailAddress)
    }


    async register(name, email, password) {
        await this.auth.createUserWithEmailAndPassword(email, password);
        return this.auth.currentUser.updateProfile({
            displayName: name
        });
    }

    gerCurrentUsername() {
        return this.auth.currentUser && this.auth.currentUser.displayName;
    }

    onAuthStateChanged(cb) {
        return this.auth.onAuthStateChanged(cb);
    }

    async getDocument(path) {
        const doc = await this.db.doc(path).get();
        if (doc.exists) {
            return doc.data();
        } else {
            return null;
        }
    }
}

const firebase = new Firebase();

export const FirebaseContext  = React.createContext(firebase);

/*
    Firebase instance provider. 
    from Component:
    const firebase = useContext(FirebaseContext)
*/
export const FirebaseProvider = ({ children }) => (
    <FirebaseContext.Provider value={firebase}>
        { children }
    </FirebaseContext.Provider>
);