import React, { useContext } from 'react';
import { FirebaseContext } from '../Firebase';


const SendWelcomeMessage = ({ match }) => {
    const firebase = useContext(FirebaseContext);
    const sendLinkToTenant = firebase.funcs.httpsCallable('sendApplicationLink');
    sendLinkToTenant({ leadId: match.params.id}).then(() => {
        console.log('message sent');
    }).catch(err => {
        //TODO: message the customer.
        console.error('something went wrong', err);
    });
    return <div></div>;
}

export default SendWelcomeMessage;