import React from 'react';
import TextField from '@material-ui/core/TextField';

const Input = ({error, required, label, helperText, onChange, value, type, autoFocus, width, name}) => {
    return (
      <TextField
        style={{width: width, marginBottom:'10px', marginRight: '5px'}}
        variant="outlined"
        margin="normal"
        error={error}
        required={required}
        label={label}
        helperText={helperText}
        type={type}
        autoFocus={autoFocus}
        onChange={onChange}
        value={value}
        name={name}
    /> );
}

Input.defaultProps = { width: '100%'}
export default Input;