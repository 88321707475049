import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import ContentContainer from '../ContentContainer';
import Section from '../ContentContainer/Section';

const HowItWorksPageTraditional = ({ property, dueToday, prepaid, savings, button, url }) => {
    return (
        <ContentContainer>
            <Section heading="Are you sure? 😬">
            You've chosen to pre-pay {prepaid} for damages. 
            Are you sure you don't want to save {savings} today by choosing to pay for damages at 
            move-out? <Link 
                        to={{
                            pathname: `${url}/how-it-works`,
                            state: { 
                                upsell: true,
                                url: url 
                            }
                        }}>Tell me more.</Link>
            </Section>
            <Section heading="Continue with pre-payment">
            On the next screen, you will be prompted to connect a 
            checking or savings account to use to pay the move-in bill of {dueToday}.
            </Section>
            <Section heading="What's next">
            After you authorize the payment of {dueToday}, we will notify {property} that your move-in bill is paid. 
            They will reach out to you with next steps.
            </Section>
            {button}
        </ContentContainer>
    );
}

export default withRouter(HowItWorksPageTraditional);