import { ENVS } from '../Constants'
import devConfig from './Configuration/dev.json';
import stagingConfig from './Configuration/staging.json';
import prodConfig from './Configuration/prod.json';
import demoConfig from './Configuration/demo.json';

/*
    Returns the correct firebase configuration based on 
    the env variable REACT_APP_RENTLINE_APP_ENV which can be:
    - 'dev'
    - 'prod'

    Doing this allows us to build production bundles of the react app that
    can target either the dev or prod firebase projects.

    NOTE: Firebase config are fine to ship in the client.

*/

export default (firebaseEnv) => {
    switch(firebaseEnv) {
        case ENVS.PROD:
            return prodConfig;
        case ENVS.STAGING:
            return stagingConfig;
        case ENVS.DEMO:
            return demoConfig;
        case ENVS.DEV:
        default:
            return devConfig;
    }
}