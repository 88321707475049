import React from 'react';
import styles from './Row.module.css';
import cn from 'classnames';

const Row = ({ heading, subheading, left, right, savings, last }) => {
    const leftClass = last ? cn(styles.flexbox_standard_left, styles.bottom_border_left) : styles.flexbox_standard_left;
    const headingClass = last ? cn(styles.deposit_table_text_bold, styles.align_left) : cn(styles.deposit_table_text, styles.align_left);
    const middleClass = last ? cn(styles.flexbox_standard_middle ,styles.bottom_border_middle) : styles.flexbox_standard_middle;
    const rightClass = last ? cn(styles.flexbox_standard_right, styles.bottom_border_right) : styles.flexbox_standard_right;
    return (
        <div className={styles.flexbox_row_standard}>
            <div className={leftClass}>
                <h3 className={headingClass}>
                    {heading + ' '} 
                    { subheading ? <><br /><span className={styles.text_span}>({subheading})</span></> : null }
                </h3>
            </div>
            <div className={middleClass}>
                <h3 className={ last ? styles.deposit_table_text_bold_sum : styles.deposit_table_text}>{left}</h3>
            </div>
            <div className={rightClass}>
                <h3 className={last ? styles.deposit_table_text_bold_sum : styles.deposit_table_text}>{right}</h3>
                { savings ? <h3 className={styles.deposit_table_percent_savings}>Save&nbsp;{savings}</h3> : null }
            </div>
        </div>
    );
}

export default Row;