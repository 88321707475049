import React from 'react';
import styles from './Option.module.css';

const Option = ({ children, selected, onSelect, id }) => {
    return (
        <div id={id} className={styles.wrapper}>
            <label className={styles.container}>{children}
                <input type="checkbox" onChange={onSelect} checked={selected} />
                <span className={styles.checkmark}/>
            </label>
        </div>
    );
}

export default Option;