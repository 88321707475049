import React, { useEffect, useContext, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import StripeRedirectPage from './StripeRedirectPage';
import PrimaryButton from '../Buttons/PrimaryButton';
import ContentContainer from '../ContentContainer';
import stripeConfig from './StripeConfig';
import { PageTitle, PageSubTitle} from '../Titles';
import PageHeader from '../PageHeader';
import { FirebaseContext } from '../Firebase';
import { MixpanelContext } from '../Mixpanel';
import StripeDone from './StripeDone';

const StripeExpressAccountPage = ({ match }) => {
    return (
        <Switch>
            <Route exact strict path={`${match.url}/redirect`} component={StripeRedirectPage} />
            <Route strict path={`${match.url}/done/:id`} component={StripeDone} />
            <Route strict path={`${match.url}/:id`} component={StripeConnectPage} />
        </Switch>
    );
}

const StripeConnectPage = ({match, history}) => {
    const [propertyName, setPropertyName] = useState("");
    const firebase = useContext(FirebaseContext);
    const mixpanel = useContext(MixpanelContext);
    const config = stripeConfig(process.env.REACT_APP_RENTLINE_APP_ENV);
    const clientId = config.client_id;
    const nonce = ''+ Math.round(Math.random() * 1000000);
    const stateValue = `${match.params.id}_${nonce}`;
    const redirectUrl = config.redirectUrl;
    const stripeUrl = `https://connect.stripe.com/express/oauth/authorize?redirect_uri=${redirectUrl}&client_id=${clientId}&state=${stateValue}&suggested_capabilities[]=transfers`;

    function handleOnclick() {
        mixpanel.track('Click', {
            button: "connect to stripe"
        });
        const propertyRef = firebase.db.doc(`properties/${match.params.id}`);
        propertyRef.update({nonce: nonce}).then(() => {
            window.location.assign(stripeUrl);
        });
    }

    useEffect(() => {
        const propertyRef = firebase.db.doc(`properties/${match.params.id}`);
        propertyRef.get().then(doc => {
            if (doc.exists) {
                const data = doc.data();
                setPropertyName(data.propertyName);
                mixpanel.register({
                    property: match.params.id,
                    propertyName: data.propertyName
                });
            } else {
                history.push('/not-found');
            }
            mixpanel.track("Page Load");
        });
    }, [history, match, firebase, mixpanel]);

    return <>
        <PageHeader>{propertyName}</PageHeader>
        <ContentContainer>
            <PageTitle>Thank you for joining the pilot.</PageTitle>
            <PageSubTitle>The last step is to connect the bank account you want to use to receive funds when renters move in.</PageSubTitle>
            <PrimaryButton onClick={handleOnclick} >Connect Your Bank Account</PrimaryButton>
            <PageSubTitle>Our payments are powered by Stripe.</PageSubTitle>
        </ContentContainer>
    </>;
}
export default StripeExpressAccountPage;