import React from 'react';
import styles from './Section.module.css';

const Section = ({ heading, children }) => {
    return (
        <div className={styles.container}>
            <h2 className={styles.heading}>{heading}</h2>
            <p className={styles.paragraph}>
                <span className={styles.paragraph}>
                    {children}
                </span>
            </p>
        </div>
    );
}

export default Section;