import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import { FirebaseProvider } from './components/Firebase';
import './components/Logging/Console';

ReactDOM.render(
    <FirebaseProvider>
        <App />
    </FirebaseProvider>,
    document.getElementById('root')
);
