import TenantEnrollmentContainer from "../TenantEnrollmentPage";
import SendWelcomeMessage from '../SendWelcomeMessage';
import StripeExpressAccountPage from '../Stripe/StripeExpressAccountPage';
import DemoPage from "../Demo/DemoPage";
import DashboardPage from '../PmDashboard/DashboardPage';

export const TENANT_ENROLL = {
    path: "/tenant/enroll/:id",
    component: TenantEnrollmentContainer,
    strict: true
};

export const TENANT_WELCOME = {
    path: "/tenant/welcome/:id",
    component: SendWelcomeMessage
}

export const STRIPE_EXPRESS = {
    path: "/pm/onboard/stripe/",
    component: StripeExpressAccountPage,
}

export const DEMO = {
    path: "/rentline/demo",
    component: DemoPage
}

export const DASHBOARD = {
    path: "/pm/dashboard/:id",
    component: DashboardPage
}