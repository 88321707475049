import log from 'loglevel';
import remote from 'loglevel-plugin-remote';

const console = window.console;
let port = window.location.port;
if (process.env.REACT_APP_RENTLINE_APP_ENV === 'local') {
    port = 5000;
}

// Set up logging.
log.enableAll();
remote.apply(log, {
    url: `${window.location.protocol}//${window.location.hostname}${port && ":" + port}/api/logger`
});

console.log = function () {
    const args = [...arguments];
    log.debug(args.map(JSON.stringify));
}

console.error = function() {
    const args = [...arguments];
    log.error(args.map(JSON.stringify));
}

console.info = function() {
    const args = [...arguments];
    log.info(args.map(JSON.stringify));
}

window.console = console;

window.addEventListener('error', function(event) {
    log.error(JSON.stringify(event));
 });