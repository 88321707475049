import React, { useContext } from 'react'; 
import { MixpanelContext } from '../../Mixpanel';
import styles from './AccountCard.module.css';

const AccountCard = ({ institution, name, mask, type, onSelect, selected }) => {
    const mixpanel = useContext(MixpanelContext);
    const stars = type === 'checking' ? '******' : '**** ';
    const inputName = name.replace(/ +/g, '-');

    function handleClick() {
        if (onSelect) onSelect(!selected);
        mixpanel.track('Selected Account', {
            institution: institution,
            name: name
        });
    }

    return (
        <div id={inputName} className={styles.card} onClick={handleClick}>
            <div className={styles.row}>
                <label className={styles.container}>
                    <input type="checkbox" onChange={onSelect} checked={selected} />
                    <span className={styles.checkmark}/>
                </label>
                <div className={styles.text}>
                    <div className={styles.name}>{institution} - {name}</div>
                    <div className={styles.number}>{stars}{mask}</div>
                </div>                
            </div>
        </div>
    );
}

export default AccountCard