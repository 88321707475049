import React from 'react';
import PropTypes from 'prop-types';
import styles from './Titles.module.css';

const PageTitle = ({ children, ...rest }) => {
    return <h2 {...rest} className={styles.pageTitle}>{children}</h2>;
}

const PageSubTitle = ({ children, style }) => {
    return <p  style={style} className={styles.pageSubtitle}>{ children }</p>;
}

PageTitle.proppTypes = {
    children: PropTypes.string.isRequired
}

PageSubTitle.propTypes = {
}

export { PageTitle, PageSubTitle };
