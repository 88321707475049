import React from 'react';
import ContentContainer from '../ContentContainer';
import { PageTitle, PageSubTitle } from '../Titles';
import Header from './Header';
import Row from './Row';

const ScreenshotableFeeForm = ({ savings, rows }) => {
    return (
        <ContentContainer>
            <PageTitle>Choose your payment</PageTitle>
            <PageSubTitle>Select the Rentline <em>No Deposit</em> option to <br /> <b style={{fontFamily: "'Linotype avenir next lt pro demi', sans-serif"}}>save {savings}</b> today.</PageSubTitle>
            <Header choiceDisabled />
            {
                rows.map((row, index) => {
                    return (
                        <Row 
                            key={index}
                            heading={row.heading}
                            subheading={row.subheading}
                            left={row.left}
                            right={row.right}
                            last={row.last}
                            savings={row.savings} />
                    );
                })
            }
            <div style={{paddingTop: '20px'}}></div>
            <PageSubTitle style={{marginBottom: '0px'}}><em>Please note: pricing is subject to change. <br /> This quote is valid until tomorrow at 5pm.</em></PageSubTitle>
        </ContentContainer>
    );
}

export default ScreenshotableFeeForm;