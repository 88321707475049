import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { MixpanelContext } from '../Mixpanel';

const QuestionMark = ({ onClick }) => {
    const mixpanel = useContext(MixpanelContext);
    function handleClickOnQuestionMark() {
        mixpanel.track('Click', {
            button: 'question-mark icon',
        });
        onClick();
    }

    return (
        <FontAwesomeIcon onClick={handleClickOnQuestionMark} icon={faQuestionCircle} />
    );
}

export default QuestionMark;