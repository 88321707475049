import React from 'react';
import PageHeader from '../PageHeader';
import PageFooter from '../PageFooter';
import { PageTitle, PageSubTitle } from '../Titles';
import ContentContainer from '../ContentContainer';
import Section from '../ContentContainer/Section';

const DonePage = ({ property, product, amount, coverage, date, mask }) => {
    return (<>
        <PageHeader>{property}</PageHeader>
        <ContentContainer>
            <PageTitle>Congrats! You're paid up!</PageTitle>
            <PageSubTitle>Get ready to move in. <br /> Rentline has withdrawn {amount} from your bank account ****{mask} to pay your move-in bill at {property}.</PageSubTitle>
            <Section heading="What's next">
                {property} will reach out to you and let you know the next step so you can move in. 
            </Section>
            {product === 'rentline' && <Section heading="When you move out">
                When you move out, {property} will send Rentline a bill for your move-out expenses. We will notify you and provide payment options to pay your move-out bill.
            </Section>}
            <Section heading="Got questions?">
                Reply to the text we sent you or call us at <br /> <a href='tel:206-502-2599'>206-502-2599</a>. Or email <a href='mailto: hello@getrentline.com'>hello@getrentline.com</a>.
            </Section>
        </ContentContainer>
        <PageFooter />
    </>);
}


export default DonePage;