import React from 'react';
import styles from './ReviewRow.module.css';
import cn from 'classnames';

const ReviewRow = ({ heading, subheading, amount, last, first }) => {
    let leftClass = last ? cn(styles.flexbox_standard_left, styles.bottom_border_left) : styles.flexbox_standard_left;
    let headingClass = last ? cn(styles.deposit_table_text_bold, styles.align_left) : cn(styles.deposit_table_text, styles.align_left);
    let amountClass = last ? cn(styles.flexbox_standard_middle ,styles.bottom_border_middle) : styles.flexbox_standard_middle;

    return (
        <div className={styles.flexbox_row_standard}>
            <div className={leftClass}>
                <h3 className={headingClass}>
                    {heading + ' '} 
                    { subheading ? <span className={styles.text_span}>({subheading})</span> : null }
                </h3>
            </div>
            <div className={amountClass}>
                <h3 className={ last ? styles.deposit_table_text_bold_sum : styles.deposit_table_text}>{amount}</h3>
            </div>
        </div>
    );
}

export default ReviewRow;