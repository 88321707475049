import React from 'react';
import styles from './PrimaryButton.module.css';
import CircularProgress from '@material-ui/core/CircularProgress';

const PrimaryButtonNoLoading = ({ children, onClick, text, disabled, loading, style }) => {

    function handleOnClick(e) {
        onClick(e);
    }

    return (
        <>
            <button style={style} disabled={disabled} onClick={(e) => handleOnClick(e)} className={styles.primary}> 
                { loading ? <CircularProgress style={{color: 'white'}}  size={32} /> : children }
            </button>
            { text && <ConfirmationText>{text}</ConfirmationText> }
        </>
    );
}


const ConfirmationText = ({ children }) => {
    return <div style={{
        width: '100%',
        color: '#777',
        fontFamily: ['Linotype avenir next lt pro','sans-serif'],
        marginTop: 10,
        padding: "0px 10px",
        fontSize: "14px"
    }}>{children}</div>
};


export default PrimaryButtonNoLoading;