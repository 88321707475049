import React from 'react';
import styles from './HowItWorksPage.module.css';
import ContentContainer from '../ContentContainer';
import Section from '../ContentContainer/Section';

const HowItWorksPage = ({ property, amount, button }) => {
    return (
        <ContentContainer>
            <Section heading="How it works">
                Save your security deposit. Instead,
                pay move-out expenses when you move out.
            </Section>
            <Section heading="Today">
                Rentline establishes a secure and limited access pre-authorization 
                to your bank account and credit card.
            </Section>
            <Section heading="At move-out">
                <span className={styles.paragraph}>1. {property} provides a move-out bill to you and Rentline.</span>
                <span className={styles.paragraph}>2. Rentline deducts the billed amount from your account of choice.</span>
            </Section>
            <Section heading="It's safe">
                Rentline will only deduct up to the coverage amount of {amount} without additional authorization from you.
            </Section>
            {button}
        </ContentContainer>
    );
}

export default HowItWorksPage;