import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollMemory from 'react-router-scroll-memory';
import * as routes from '../AppRoutes';
import NoMatch from '../ErrorPages/404';

function App() {
  return (<div>
    {router}
  </div>
  );
}

const router = (
  <Router>
    <ScrollMemory />
    <Switch>
      {Object.keys(routes).map(key => routes[key]).map(route => (
          <Route exact={!!route.exact} strict={!!route.strict} key={route.path} path={route.path} component={route.component} />
      ))}
        <Route component={NoMatch} />
    </Switch>
  </Router>
);
export default App;
