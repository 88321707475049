import React from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';
import DotProgressBar from '../ProgressBars/DotProgressBar';
import ContentContainer from '../ContentContainer/ContentContainer';

const MultiViewForm = ({ children, match, history }) => {
    const stepCount = React.Children.count(children);

    const StepView = ({ stepCount, activeStep }) => {
        function getBody(elements, index) {
            return elements.constructor === Array ? elements[index] : elements;
        }
    
        function getButton(elements, index) {
            const button = getBody(elements, index).props.nextButton;
            if (button) {
                if(typeof button === 'function') {
                    return button(handleClick);
                } else {
                    return button;
                }
            } else {
                return null;
            }
        }

        function handleClick() {
            history.push(`${match.url}/${activeStep + 1}`);
        }

        return (
            <ContentContainer>
                <DotProgressBar options={stepCount} selected={activeStep} />
                {
                    getBody(children, activeStep)
                }
                {
                    getButton(children, activeStep)
                }
            </ContentContainer>
        );
    }

    return (
        <Switch>
            <Route exact path={match.url} render = {() => (
                <StepView stepCount={stepCount} activeStep={0}/>
            )} />
            <Route strict path={`${match.url}/:stepId`} render = {({ match }) => {
                if (match.params.stepId < stepCount) {
                    return <StepView stepCount={stepCount} activeStep={parseInt(match.params.stepId)}/>;
                } else {
                    return null;
                }
            }} />
        </Switch>
    );

}

export default withRouter(MultiViewForm);