import React, { useState, useContext, useEffect} from 'react';
import { FirebaseContext } from '../Firebase';
import ErrorDialog from '../Dialogs/ErrorDialog';
import ContentContainer from '../ContentContainer';
import PageHeader from '../PageHeader/PageHeader';
import Input from '../Forms/Fields/Input';
import Section from '../ContentContainer/Section/Section';
import InputMask from "react-input-mask";
import PrimaryButton from '../Buttons/PrimaryButton/PrimaryButton';
import lead from './Lead';
import property from './Property'
import { PageTitle } from '../Titles/Titles';
import Card from '@material-ui/core/Card';
import { MixpanelContext } from '../Mixpanel';

const DemoPage = () => {
    const [phone, setPhone] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [firstName, setFirstName] = useState("");
    const [error, setError] = useState(false);
    const [done, setDone] = useState(false);
    const mixpanel = useContext(MixpanelContext);
    const firebase = useContext(FirebaseContext);

    useEffect(() => {
        mixpanel.track("Page Load");
    }, [mixpanel])

    function handleFieldChange(event) {
        const phoneNumber = event.target.value.replace(/\D/g, '');
        setPhone(phoneNumber);
        if (phoneNumber.length === 10) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }

    async function handleStart() {

        if (firstName) {
            lead.tenant.firstName = firstName;
        }

        mixpanel.identify(phone);
        mixpanel.track('Demo Start', {
            name: firstName
        });
        lead.tenant.phone = phone;

        try {

            const createProperty = firebase.funcs.httpsCallable('createPropertyFunction');
            const propertyRes = await createProperty({
                property: property
            });
            lead.propertyId = propertyRes.data;

            const createLead = firebase.funcs.httpsCallable('createLeadFunction');
            const leadRes = await createLead({
                lead: lead
            });
            const leadId = leadRes.data;

            const sendLinkToTenant = firebase.funcs.httpsCallable('sendApplicationLink');
            await sendLinkToTenant({
                leadId: leadId
            });

            setDone(true);
        } catch (err) {
            console.error(err);
            setError(true);
        }
    }

    let content = <>
            <Section>
                Please enter your phone number below to start the Rentline demo.
            </Section>
            <InputMask name="phone" mask="(999) 999-9999" maskChar=" " value={phone} onChange={handleFieldChange}>
                <Input autoFocus type="tel" label="Phone Number" required helperText="Rentline will text you from (850) 852-8553"/>
            </InputMask>
            <Input name="first-name" type="text" label="First Name" helperText="Optionally, provide your first name" value={firstName} onChange={event => setFirstName(event.target.value)}/>
            <PrimaryButton name="send" disabled={disabled} onClick={handleStart}>Start Demo</PrimaryButton>
    </>;

    if (done) {
        content = <>
            <PageTitle name="thank-you-message">Thanks!</PageTitle>
            <Section>
                
                When prompted for bank account information please use the following: <br />
                <Card style={{padding: 20, marginTop:20}}>
                <strong>Bank of America</strong> <br />
                <strong>Username: </strong> user_good<br />
                <strong>Password: </strong> pass_good<br />
                </Card>
            </Section>
        </>;
    }

    return (<>
        <PageHeader>Demo</PageHeader>
        <ContentContainer>
           {content}
        </ContentContainer>
        <ErrorDialog open={error} handleClose={() => setError(false)} />
    </>);
}

export default DemoPage;