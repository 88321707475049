import React, { useContext, useEffect, useState } from 'react';
import styles from './Stripe.module.css';
import { FirebaseContext } from '../Firebase';
import { MixpanelContext } from '../Mixpanel';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorDialog from '../Dialogs/ErrorDialog';

const StripeRedirectPage = ({ location, history }) => {
    const [error, setError] = useState(false);
    const firebase = useContext(FirebaseContext);
    const mixpanel = useContext(MixpanelContext);
    const  fetchStripeCredentials = firebase.funcs.httpsCallable('fetchStripeCredentials');

    useEffect(() => {
        const search = location.search;
        const params = new URLSearchParams(search);
        const code = params.get('code');
        const state = params.get('state');
        const stateTokens = state.split('_');
        const propertyId = stateTokens[0];
        const nonce = stateTokens[1];

        fetchStripeCredentials({
            authorizationCode: code,
            nonce: nonce,
            propertyId: propertyId
        }).then((result) => {
            mixpanel.track('Stripe Credentials', {
                propertyId: propertyId
            })
            history.replace(`/pm/onboard/stripe/done/${propertyId}`);
        }).catch((error) => {
            console.log(error);
            setError(true);
        });
    }, [fetchStripeCredentials, history, mixpanel, location]);

    return (<>
        <div className={styles.centered}>
            <CircularProgress style={{color: '#253171'}}  size={32} />
        </div>
        <ErrorDialog open={error} handleClose={() => setError(false)} />
    </>);
}

export default StripeRedirectPage;