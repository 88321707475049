import currency from 'currency.js';


export const toWholeDollars = (amount) => currency(amount, {formatWithSymbol: true, precision: 0}).format();

export const getFeeRow = (heading, subheading, left, right, last, savings) => {
    return {
        heading: heading,
        subheading: subheading,
        left: left,
        right: right,
        last: last,
        savings: savings
    };
}