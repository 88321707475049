import React, { useState } from 'react';
import PageHeader from '../../PageHeader/PageHeader';
import PageFooter from '../../PageFooter/PageFooter';
import UnitForm from '../../Forms/UnitForm/UnitForm';
import ItemizedFeeForm from '../../Forms/Fields/ItemizedFeeForm/ItemizedFeeForm';
import PrimaryButtonNoLoading from '../../Buttons/PrimaryButton/PrimaryButtonNoLoading';
import ContentContainer from '../../ContentContainer/ContentContainer';

const UnitAddPage = ({ propertyName, newUnit, onSave, onCancel }) => {
    const [unit, setUnit] = useState(newUnit);
    const [deposits, setDeposits] = useState(newUnit.deposits || []);
    const [fees, setFees] = useState(newUnit.fees || []);
    const [rents, setRents] = useState(newUnit.rents || []);
    const [error, setError] = useState({});

    function setBedrooms(bedrooms) {
        setUnit(oldUnit => {
            const newUnit = {...oldUnit};
            newUnit.bedrooms = bedrooms;
            return newUnit;
        });
    }

    function setBathrooms(bathrooms) {
        setUnit(oldUnit => {
            const newUnit = {...oldUnit};
            newUnit.bathrooms = bathrooms;
            return newUnit;
        });
    }

    function setUnitNumber(number) {
        setUnit(oldUnit => {
            const newUnit = {...oldUnit};
            newUnit.number = number;
            return newUnit;
        });
    }

    function setSquareFootage(squareFootage) {
        setUnit(oldUnit => {
            const newUnit = {...oldUnit};
            newUnit.squareFootage = squareFootage;
            return newUnit;
        });
    }

    function setAvailableDate(availableDate) {
        setUnit(oldUnit => {
            const newUnit = {...oldUnit};
            newUnit.availableDate = availableDate.format('YYYY-MM-DD');
            return newUnit;
        });
    }

    function handleRemoveFeeItem(index, type) {
        if (type === 'rent') {
            setRents(oldRents => {
                const newRents = [...oldRents];
                newRents.splice(index, 1);
                return newRents;
            });
        }
        if (type === 'deposit') {
            setDeposits(oldDeposits => {
                const newDeposits = [...oldDeposits];
                newDeposits.splice(index, 1);
                return newDeposits;
            });
        }
        if (type === 'fee') {
            setFees(oldFees => {
                const newFees = [...oldFees];
                newFees.splice(index, 1);
                return newFees;
            });
        }

    }

    function handleOnFeeItemAdded(item) {
        if (item.type === 'rent') {
            setRents(oldRents => {
                const newRents = [...oldRents];
                newRents.push({
                    name: item.name,
                    amount: item.amount
                });
                return newRents;
            });
        }
        if (item.type === 'fee') {
            setFees(oldFees => {
                const newFees = [...oldFees];
                newFees.push({
                    name: item.name,
                    amount: item.amount
                });
                return newFees;
            });
        }
        if (item.type === 'deposit') {
            setDeposits(oldDeposits => {
                const newDeposits = [...oldDeposits];
                newDeposits.push({
                    name: item.name,
                    amount: item.amount
                });
                return newDeposits;
            });
        }

    }

    function handleSubmit() {
       if (validate()) {
            unit.deposits = deposits;
            unit.rents = rents;
            unit.fees = fees;
            unit.created = new Date();
            onSave(unit);
       }
    }

    function validate() {
        const error = {};
        let valid = true;

        if(!unit.bedrooms || unit.bedrooms < 0) {
            error.bed = true;
            valid = false;
        }

        if(!unit.bathrooms || unit.bathrooms < 0) {
            error.bath = true;
            valid = false;
        }
        
        setError(error);
        return valid;
    }

    return (<>
        <PageHeader>{propertyName}</PageHeader>
        <ContentContainer>
            <UnitForm
                onBedroomChanged={event => setBedrooms(event.target.value)} 
                onBathroomChanged={event => setBathrooms(event.target.value)} 
                onUnitNumberChanged={event => setUnitNumber(event.target.value)} 
                onSquareFootageChanged={event => setSquareFootage(event.target.value)} 
                onAvailableDateChanged={date => setAvailableDate(date)} 
                error={error}
                unit={unit}
            />
            <div style={{height: '30px'}} />
            <ItemizedFeeForm 
                deposits={deposits}
                fees={fees}
                rents={rents}
                onAdd={handleOnFeeItemAdded}
                onRemove={handleRemoveFeeItem}
            />
            <PrimaryButtonNoLoading onClick={handleSubmit}>Save</PrimaryButtonNoLoading>
            <PrimaryButtonNoLoading onClick={onCancel}>Cancel</PrimaryButtonNoLoading>
        </ContentContainer>
        <PageFooter />
    </>);
}

export default UnitAddPage;