import React from 'react';
import { PageTitle, PageSubTitle } from '../Titles';
import Header from './Header';
import Row from './Row';

const FeeForm = ({ title, subtitle, rows, onChoose, choice }) => {

    return (<>
        <PageTitle>{title}</PageTitle>
        <PageSubTitle>{subtitle}</PageSubTitle>
        <Header onChoose={onChoose} choice={choice}/>
        {
            rows.map((row, index) => {
                return (
                    <Row 
                        key={index}
                        heading={row.heading}
                        subheading={row.subheading}
                        left={row.left}
                        right={row.right}
                        last={row.last}
                        savings={row.savings} />
                );
            })
        }
    </>);
}

export default FeeForm;