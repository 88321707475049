import React, { useState, useContext } from 'react';
import { MixpanelContext } from '../../../Mixpanel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import PrimaryButtonNoLoading from '../../../Buttons/PrimaryButton/PrimaryButtonNoLoading';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Input from '../Input';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import DeleteIcon from '@material-ui/icons/Delete';
import { DialogActions } from '@material-ui/core';

const ItemizedFeeForm = ({ deposits, fees, rents, onAdd, onRemove}) => {
    const [addDialog, showAddDialog] = useState(false);
    const [newFee, setNewFee] = useState({type: 'rent'});
    const [error, setError] = useState({amount: false, name: false});
    const mixpanel = useContext(MixpanelContext);

    function handleClick() {
        mixpanel.track('Click', {
            button: 'add an itemized fee'
        });
        showAddDialog(true);
    }

    function handleClose() {
        mixpanel.track('Click', {
            button: 'cancel add itemized fee'
        });
        showAddDialog(false);
    }

    function handleNameChange(event) {
        const value = event.target.value;
        setNewFee(oldFee => {
            const newFee = { ...oldFee};
            newFee.name = value;
            return newFee;
        });
    }

    function handleTypeChange(event) {
        const value = event.target.value;
        setNewFee(oldFee => {
            const newFee = { ...oldFee};
            newFee.type = value;
            return newFee;
        });
    }

    function handleAmountChange(event) {
        const value = event.target.value;
        setNewFee(oldFee => {
            const newFee = { ...oldFee};
            newFee.amount = value;
            return newFee;
        });
    }

    function handleAdd() {
        if (newFee.name && newFee.amount) {
            mixpanel.track('Added Itemized Fee', {
                type: newFee.type
            });
            onAdd(newFee);
            setError({amount: false, name: false});
            setNewFee({type: 'rent'});
            showAddDialog(false);
        } else {
            setError({name: !newFee.name, amount: !newFee.amount});
        }
    }

    function handleDelete(key, type) {
        mixpanel.track('Click', {
            button: 'delete itemized fee',
            type: type
        });
        mixpanel.track('Delete Itemized Fee', {
            type: type
        });
        
        onRemove(key, type);
    }

    function renderFeeList(heading, fees, type) {
        const feeRows = [];
        fees.forEach((fee, index) => {
            feeRows.push(row(fee, index, type));
        });
    
        return (<>
            <h4>{heading}</h4>
            <Table size="small" style={{marginBottom: '50px'}}>
                <TableBody>
                    {feeRows}
                </TableBody>
            </Table>
        </>);
    }
    
    function row(fee, key, type) {
        return (
            <TableRow key={key}>
                <TableCell>{fee.name}</TableCell>
                <TableCell align="center">{fee.amount}</TableCell>
                <TableCell align="right"><DeleteIcon color='action' onClick={() => handleDelete(key, type)}/></TableCell>
            </TableRow>
        );
    }

    return (<>
            { rents && rents.length > 0 ? renderFeeList("Rents", rents, 'rent') : null }
            { deposits && deposits.length > 0 ? renderFeeList("Deposits", deposits, 'deposit') : null }
            { fees && fees.length > 0 ? renderFeeList("Fees", fees, 'fee') : null }
            <PrimaryButtonNoLoading onClick={handleClick}>Add a Fee</PrimaryButtonNoLoading>
                <Dialog open={addDialog} onClose={handleClose}>
                    <div style={{paddingLeft: '20px', paddingRight: '20px', marginBottom: '20px'}}>
                        <DialogTitle>Add</DialogTitle>
                        <Input label="Name" autoFocus required error={error.name} onChange={handleNameChange}/>
                        <Input label="Amount" required  error={error.amount}  type='number' onChange={handleAmountChange}/>
                        <Select 
                            required
                            variant='outlined'
                            style={{width:'100%', marginTop: '16px'}}
                            input={<OutlinedInput name="type" />}
                            onChange={handleTypeChange}
                            value={newFee.type}>
                            <MenuItem value="rent">Rent</MenuItem>
                            <MenuItem value="deposit">Deposit</MenuItem>
                            <MenuItem value="fee">Fee</MenuItem>
                        </Select>
                        <DialogActions>                        
                            <PrimaryButtonNoLoading onClick={handleAdd}>Add</PrimaryButtonNoLoading>
                        <PrimaryButtonNoLoading onClick={handleClose}>Cancel</PrimaryButtonNoLoading>
                        </DialogActions>
                    </div>
                </Dialog>
    </>);
}


export default ItemizedFeeForm;