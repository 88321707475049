import { ENVS } from '../Constants';
const devToken = "66062c8d37335e2dd0020498d36532f5";
const stagingToken = "51c65bb69cf7f7926867afdce4b40fdb";
const prodToken = "677a7bf5d4dc6a83eb0ad87de2004d2d";

export default (mixpanelEnv) => {
    switch(mixpanelEnv) {
        case ENVS.PROD:
            return prodToken;
        case ENVS.DEMO:
            return prodToken;
        case ENVS.STAGING:
            return stagingToken;
        default:
            return devToken;
    }
}