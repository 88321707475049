import { ENVS } from '../Constants';

const redirectUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port && ":" + window.location.port}/pm/onboard/stripe/redirect`
const testConfig = {
    client_id: "ca_FeXR99EdknHLffB8q8Cd45lKRgAnbhsk",
    redirectUrl: redirectUrl
}

const liveConfig = {
    client_id: "ca_FeXRQtVWXqgEHSKKB6t1FbkogwiPoZlu",
    redirectUrl: redirectUrl
}

export default (stripeEnv) => {
    switch(stripeEnv) {
        case ENVS.PROD:
            return liveConfig;
        case ENVS.STAGING:
            return liveConfig;
        default:
            return testConfig;
    }
}