import { toWholeDollars, getFeeRow as getRow } from '../Helpers';

export const mergeAndDedupe = (source, addition) => {
    let filteredAddition = [];
    source.forEach(element => {
        filteredAddition = addition.filter(addedElement => {
            return !(element.id === addedElement.id && element.institutionId === addedElement.institutionId);
        })
    });

    return source.concat(filteredAddition);
}

export const sumFees = (fees) => {
    if (fees) {
        return fees.map(fee => {
            return parseFloat(fee.amount);
        }).reduce((sum, currentFee) => sum += currentFee, 0);
    } else {
        return 0;
    }
}

export const getRows = (coverage, rentlineRow) => {
    const rows = [];
    function descending (a, b) {
        return b.amount - a.amount;
    } 

    if (coverage.deposits) {
        coverage.deposits.sort(descending).forEach(deposit => {
            const amount =  toWholeDollars(deposit.amount);
            rows.push(getRow(deposit.name, deposit.helpText, amount, "-"));
        });
    }

    rows.push(rentlineRow);

    if (coverage.fees) {
        coverage.fees.sort(descending).forEach(fee => {
            const amount =  toWholeDollars(fee.amount);
            rows.push(getRow(fee.name, fee.helpText, amount, amount));
        });
    }

    
    if (coverage.rents) {
        coverage.rents.sort(descending).forEach(rent => {
            const amount =  toWholeDollars(rent.amount);
            rows.push(getRow(rent.name, rent.helpText, amount, amount));
        });
    }

    return rows;
}

export const calculateRentlineFee = (coverageAmount) => coverageAmount * 0.1;

export const getSavingsString = (fullprice, discountedPrice) => {
    const savings = fullprice - discountedPrice;
    return toWholeDollars(savings);
}

export const getFeeRow = getRow;