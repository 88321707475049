import React, { useContext } from 'react';
import { MixpanelContext } from '../Mixpanel';
import styles from './PageFooter.module.css';
import FooterLogo from '../../Assets/Images/Logos/rl-footer-logo.png';
import FooterLogo500 from '../../Assets/Images/Logos/rl-footer-logo-500.png';

const PageFooter = () => {
    const mixpanel = useContext(MixpanelContext);

    function handleClick(event, element) {
        if (event.target) {
            let href = event.target.href;
            if (!href) {
                href = event.target.parentNode.href;
            }
            mixpanel.track('Click', {
                href: href,
                element: element
            });
        }
    }

    return (
        <div className={styles.section_footer_v2}>
            <div className={styles.container_1200_max}>
                <a target="_blank" rel="noopener noreferrer" href="https://getrentline.com/tenant/enroll/more" onClick={(event) => handleClick(event, 'footer: logo')}>
                    <img src={FooterLogo} height="36" srcSet={`${FooterLogo500} 500w, ${FooterLogo} 1004w`} onClick={(event) => {}} sizes="138px" alt="" className={styles.logo_footer}/>
                </a>
                <p className={styles.footer_paragraph} onClick={() => handleClick('')}>
                    <a target="_blank" rel="noopener noreferrer" href="https://getrentline.com/tenant/enroll/more" onClick={(event) => handleClick(event, 'footer: learn more')}>Learn more</a>
                    &nbsp;|&nbsp;
                    <a target="_blank" rel="noopener noreferrer" href="https://getrentline.com/tenant/enroll/contact" onClick={(event) => handleClick(event, 'footer: contact us')}>Contact us</a>
                </p>
            </div>
        </div>
    );
}

export default PageFooter;