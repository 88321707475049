import React from 'react';
import PageHeader from '../PageHeader';
import PageFooter from '../PageFooter';
import MultiViewForm from '../MultiViewForm/MultiViewForm';
import FeeForm from '../FeeForm/FeeForm';
import HowItWorksPage from '../HowItWorksPage/HowItWorksPage';
import HowItWorksPageTraditional from '../HowItWorksPage/HowItWorksPageTraditional';
import PlaidLink from '../Plaid/Link';
import PrimaryButton from '../Buttons/PrimaryButton';
import ConnectedAccounts from '../ConnectedAccounts/ConnectedAccounts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight} from '@fortawesome/free-solid-svg-icons';
import QuestionMark from '../QuestionMark';

const TenantEnrollmentPage = ({
    propertyName, 
    coverageAmount, 
    rows, 
    onChoose,
    choice,
    onPlaidSuccess, 
    onPlaidExit, 
    checkingAccounts, 
    creditAccounts, 
    checkingAccount,
    creditCardAccount,
    onReadyForReview,
    dueToday,
    savings,
    product,
    history,
    match}) => {

    const nextButtonText = <><span>Next <FontAwesomeIcon icon={faArrowAltCircleRight} /></span></>;

    /**
     * An attempt to restore scroll position after Plaid link exit.
     */
    function handleSavingScrollPosition() {
        sessionStorage.setItem('plaid-scroll-pos', JSON.stringify({x: window.scrollX, y: window.scrollY}));
    }

    function handleOnPlaidExit (err, metadata) {
        const scrollPos = JSON.parse(sessionStorage.getItem('plaid-scroll-pos'));
        onPlaidExit(err, metadata);
        if (scrollPos) {
            // Only works with setTimeout.
            setTimeout(() => window.scrollTo(scrollPos.x, scrollPos.y), 0);
            sessionStorage.removeItem('plaid-scroll-pos');
        }

    }

    function handleHowItWorksButtonClick(onClick) {
        const extraText = product === 'rentline' ? ' and pre-authorize payment for expenses when you move out' : ''
        if (checkingAccounts.length || creditAccounts.length) {
            return <PrimaryButton name="next" onClick={onClick}>{nextButtonText}</PrimaryButton>;
        } else {
            return (
            <div style={{marginTop: '-50px'}}>
                <PlaidLink 
                    name="next"
                    onClick={handleSavingScrollPosition}
                    onSuccess={(publicToken, metadata)  => {onPlaidSuccess(publicToken, metadata); onClick()}} 
                    onExit={handleOnPlaidExit}>Connect Accounts
                </PlaidLink>
                <div style={{
                    width: '100%',
                    color: '#777',
                    fontFamily: ['Linotype avenir next lt pro','sans-serif'],
                    marginTop: 10,
                    padding: "0px 10px",
                    fontSize: "14px"
                }}>We partner with <a href="https://plaid.com" target="_blank" rel="noopener noreferrer">Plaid</a> and <a href="https://stripe.com" target="_blank" rel="noopener noreferrer">Stripe</a> to connect with your bank account and credit card so you can pay today's move-in expenses{extraText}.</div>
            </div>);
        }
    }

    
    return (<>
        <PageHeader>{propertyName}</PageHeader>
        <MultiViewForm>
            <FeeForm 
                title="Choose your payment"
                subtitle={<>
                    <span>Select the Rentline <em>No Deposit</em> option to 
                    <b style={{fontFamily: "Linotype avenir next lt pro Demi"}}> save&nbsp;{savings}&nbsp;today </b>
                    </span><QuestionMark onClick={() => {history.push(`${match.url}/how-it-works`)}}/>
                </>}
                rows = {rows}
                onChoose={onChoose}
                choice={choice}
                nextButton={onClick => {
                    let confirmationText = `You've selected the Pre-paid Deposit option for a total move-in payment of ${dueToday}.`;
                    if (product === 'rentline') {
                        confirmationText = `You've selected the No Deposit option for a total move-in payment of ${dueToday}.`
                    }
                    return <PrimaryButton name="next" text={confirmationText} onClick={onClick}>{nextButtonText}</PrimaryButton>;
                }}
            />
            {
                product === 'rentline' ?
                <HowItWorksPage 
                    property={propertyName} 
                    amount={coverageAmount}  
                    nextButton={onClick => handleHowItWorksButtonClick(onClick)} 
                />  :
                <HowItWorksPageTraditional
                    property={propertyName}
                    dueToday={dueToday}
                    prepaid={coverageAmount}
                    savings={savings}
                    url={match.url}
                    nextButton={onClick => handleHowItWorksButtonClick(onClick)} 
                />
            }

            <ConnectedAccounts 
                checkingAccounts={checkingAccounts} 
                creditAccounts={creditAccounts}
                hiwButton={<QuestionMark onClick={() => {history.push(`${match.url}/how-it-works`)}}/>}
                onPlaidSuccess={onPlaidSuccess}
                onPlaidExit={onPlaidExit}
                product={product}
                dueToday={dueToday}
                nextButton={
                    () => {
                        let enabled = checkingAccount && creditCardAccount;
                        let buttonText = "Connect Accounts";

                        if (product === 'traditional') {
                            buttonText = nextButtonText;
                            enabled = !!checkingAccount;
                        }

                        let confirmationText = 'You need to connect a checking account and credit card to continue. You will not be charged yet.';
                        if (enabled) {
                            confirmationText = 'You will not be charged yet. Continue on to see your final move-in bill and authorize the charges.';
                        }
                        return <PrimaryButton name="next" text={confirmationText} disabled={!enabled} onClick={() => onReadyForReview()}>{buttonText}</PrimaryButton>;
                    }
                }
            />
        </MultiViewForm>
        <PageFooter />
    </>);
}

export default TenantEnrollmentPage;