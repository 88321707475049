import React, { useState, useContext } from 'react';
import { MixpanelContext } from '../Mixpanel';
import styles from './PaymentReviewPage.module.css';
import currency from 'currency.js';
import PageHeader from '../PageHeader';
import PageFooter from '../PageFooter';
import { PageTitle, PageSubTitle } from '../Titles';
import Row from './ReviewRow';
import Option from '../Option';
import PrimaryButton from '../Buttons/PrimaryButton';
import ContentContainer from '../ContentContainer/ContentContainer';

const PaymentReviewPage = ({ property, title, subtitle, rows, amount, onAuthorize, choice, account, product, dueToday}) => {
    const [consent, setConsent] = useState({
        rentlineAuthorized: false,
        tenantResponsible: false,
        agreeToConditions: false
    });

    const mask = account && account.mask;
    const mixpanel = useContext(MixpanelContext);

    function giveConsent(to) {
        setConsent(consent => {
            const newConsent = {...consent};
            newConsent[to] = !consent[to];
            const params = {};
            params[to] = newConsent[to];
            params.choice = choice;
            mixpanel.track("Give Consent", params);
            return newConsent
        })
    }

    function authorize() {
        mixpanel.track('Payment Authorized', {
            choice: choice
        });
        onAuthorize();
    }

    function handleAuthorizePayment() {
        if (product === 'traditional') {
            if (consent.agreeToConditions) {
               authorize();
            }
        } else {
            if (consent.rentlineAuthorized && consent.tenantResponsible && consent.agreeToConditions) {
                authorize();
            }
        }
    }

    function handleLinkClick(event, element) {
        if (event.target) {
            let href = event.target.href;
            if (!href) {
                href = event.target.parentNode.href;
            }
            mixpanel.track('Click', {
                href: href,
                element: element
            });
        }
    }

    const amountString = currency(amount, {formatWithSymbol: true, precision: 0}).format();
    let optionList = (
        <>
            <Option id="tenantResponsible" selected={consent.tenantResponsible} onSelect={() => giveConsent("tenantResponsible")}>
                I have selected the <em>No Deposit</em> option and understand I am responsible to pay all damages at move-out.
            </Option>
            <Option id="rentlineAuthorized" selected={consent.rentlineAuthorized} onSelect={() => giveConsent("rentlineAuthorized")}>
                I authorize Rentline to withdraw up to {amountString} from my bank account or credit card to pay the move-out bill from {property}.
            </Option>
            <Option id="agreeToConditions" selected={consent.agreeToConditions} onSelect={() => giveConsent("agreeToConditions")}>
                I agree to Rentline’s <a target="_blank" rel="noopener noreferrer" href="https://getrentline.com/tenant/enroll/terms" onClick={(event) => handleLinkClick(event, 'agreeToConditions: terms and conditions')}>terms and conditions</a> and 
                I understand <a target="_blank" rel="noopener noreferrer" href="https://getrentline.com/tenant/enroll/more" onClick={(event) => handleLinkClick(event, 'agreeToConditions: learn more')}>How the <em>No Deposit</em> option works.</a> 
            </Option>
        </>
    );

    if (product === 'traditional') {
        optionList = (
            <Option id="agreeToConditions" selected={consent.agreeToConditions} onSelect={() => giveConsent("agreeToConditions")}>
            I agree to Rentline’s <a target="_blank" rel="noopener noreferrer" href="https://getrentline.com/tenant/enroll/terms" onClick={(event) => handleLinkClick(event, 'agreeToConditions: terms and conditions')}>terms and conditions</a> and 
            I authorize Rentline to withdraw {dueToday} from my bank account to pay the move-in bill. 
            </Option>
        );
    }

    return (<>
        <PageHeader>{property}</PageHeader>
            <ContentContainer>
                <PageTitle>{title}</PageTitle>
                <PageSubTitle>{subtitle}</PageSubTitle>
                <ReviewTableHeader>Move-in expenses</ReviewTableHeader>
                {
                    rows.map((row, index) => {
                        return (
                            <Row 
                                key={index}
                                heading={row.heading}
                                subheading={row.subheading}
                                amount={row.left}
                                right={row.right}
                                first={index === 0}
                                last={index === rows.length - 1}
                                savings={row.savings} />
                        );
                    })
                }
                <div className={styles.buffer_top}>
                    {optionList}
                </div>
                <div className={styles.buffer_top}>
                    <PrimaryButton
                        id="authorizePayment"
                        disabled={!(consent.agreeToConditions && ((consent.rentlineAuthorized && consent.tenantResponsible) || product === 'traditional'))}
                        onClick={handleAuthorizePayment} 
                        text={`${dueToday} will be withdrawn from your account ****${mask} to pay your move-in bill at ${property}.`}>
                            Authorize Payment
                    </PrimaryButton> 
                </div>
            </ContentContainer>
        <PageFooter />
    </>);
}


const ReviewTableHeader = ({ children }) => {
    return  (
        <div className={styles.heading_flexbox_row}>
            <div className={styles.heading_cell_traditional}>
                {children}
            </div>
        </div>
    );
}

export default PaymentReviewPage;