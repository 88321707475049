import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import ErrorIcon from '@material-ui/icons/Error';

const ErrorDialog = ({ open, handleClose }) => {
    
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >   
            <DialogTitle id="alert-dialog-title">
                <span style={{ display: 'flex', alignItems: 'center'}}>
                    <ErrorIcon style={{marginRight: '10px', color: 'red'}}/>  Error
                </span>
            </DialogTitle>

            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    An unknown error has occurred. 
                    The Rentline team has been notified and we will reach out to you via text with next steps. 
                    We apologize for the inconvenience.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
      </Dialog>
    );
}

export default ErrorDialog;