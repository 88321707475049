import React, { useEffect, useState, useContext } from 'react';
import { FirebaseContext } from '../Firebase';
import { MixpanelContext } from '../Mixpanel';
import ContentContainer from '../ContentContainer';
import { PageTitle, PageSubTitle} from '../Titles';
import PageHeader from '../PageHeader';

const StripeDone = ({ match }) => {
    const [propertyName, setPropertyName] = useState("");
    const firebase = useContext(FirebaseContext);
    const mixpanel = useContext(MixpanelContext);

    useEffect(() => {
        const propertyRef = firebase.db.doc(`properties/${match.params.id}`);
        propertyRef.get().then(doc => {
            if (doc.exists) {
                const data = doc.data();
                setPropertyName(data.propertyName);
            }
            mixpanel.track("Page Load", {
                propertyId: match.params.id
            });
        });
    }, [ match, firebase, mixpanel]);

    return (<>
        <PageHeader>{propertyName}</PageHeader>
        <ContentContainer>
            <PageTitle>Success! You're all set.</PageTitle>
            <PageSubTitle>You have granted Rentline access to send money to your bank account&nbsp;when&nbsp;renters&nbsp;move&nbsp;in.</PageSubTitle>
            <PageSubTitle>We will follow up shortly to schedule a 20-minute training session&nbsp;to&nbsp;kick&nbsp;things&nbsp;off.</PageSubTitle>
        </ContentContainer>
    </>);
}

export default StripeDone;
