import React from 'react';
import PropTypes from 'prop-types';

const DotProgressBar = ({ options, selected }) => {
    const lines = [];
    const circles = [];
    const lineWidth = 60;
    const selectedFillColor = "#24B37E";
    const fillColor = "#D5D5D5";
    const svgWidth = 60*options;
    const svgViewBodWidth = svgWidth - 40;

    for (let index = 0; index < options; index++) {
        lines.push(<rect id="Rectangle" key={index} fill="#D5D5D5" x={10 + 60*index} y="9" width={lineWidth} height="2"></rect>);
        if (index === selected) {
            circles.push(<circle id="Oval" key={'s' + index} fillOpacity="0.2" fill={selectedFillColor} cx={10 + 60*index} cy="10" r="10"></circle>);
            circles.push(<circle id="Oval" key={index} fill={selectedFillColor} cx={10 + 60*index} cy="10" r="5"></circle>);
        } else {
            circles.push(<circle id="Oval" key={index} fill={fillColor} cx={10 + 60*index} cy="10" r="5"></circle>)
        }
    }

    lines.pop();
    return (
        <svg width={svgWidth +'px'} height="20px" viewBox={"0 0 " + svgViewBodWidth + " 20"} version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Letter" transform="translate(-966.000000, -257.000000)" fillRule="nonzero">
                    <g id="Group-3" transform="translate(848.000000, 103.000000)">
                        <g id="rentline_pilot_choose_deposit_2B">
                            <g id="Progress-bar" transform="translate(118.000000, 154.000000)">
                                { lines }
                                { circles }
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

DotProgressBar.propTypes = {
    options: PropTypes.number.isRequired,
    selected: PropTypes.number.isRequired
}

export default DotProgressBar;