import React, { useState, useEffect } from 'react';
import styles from './PrimaryButton.module.css';
import CircularProgress from '@material-ui/core/CircularProgress';

const PrimaryButton = ({ children, onClick, text, disabled, ...rest }) => {
    const [buttonDisabled, setDisabled] = useState(disabled);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setDisabled(disabled);
    }, [disabled]);

    function handleOnClick(e) {
        if (!buttonDisabled) {
            setDisabled(true);
            setLoading(true);
            onClick(e);
        }
    }

    return (
        <>
            <button {...rest} disabled={buttonDisabled} onClick={(e) => handleOnClick(e)} className={styles.primary}> 
                { loading ? <CircularProgress style={{color: 'white'}}  size={32} /> : children }
            </button>
            { text && <ConfirmationText>{text}</ConfirmationText> }
        </>
    );
}


const ConfirmationText = ({ children }) => {
    return <div style={{
        width: '100%',
        color: '#777',
        fontFamily: ['Linotype avenir next lt pro','sans-serif'],
        marginTop: 10,
        padding: "0px 10px",
        fontSize: "14px"
    }}>{children}</div>
};


export default PrimaryButton;