import React, { useContext } from 'react';
import { MixpanelContext } from '../Mixpanel';
import styles from './PageHeader.module.css'; 
import cn from 'classnames';

// import { ReactComponent as Logo } from '../../Assets/Images/Logos/rentline-logo.svg';
import logo from '../../Assets/Images/Logos/rentline-logo.png';
import poweredByLogo from '../../Assets/Images/Logos/rentline-poweredby-logo.png';

const PageHeader = ({ children }) => {
    const mixpanel = useContext(MixpanelContext);

    function handleClick(element) {
        mixpanel.track('Dead Click', {
            element: element
        });
    }

    return (
        <div className={styles.headerSection}>
            <div className={cn(styles.wclearfix,styles.headerContainer)}>
                <div className={styles.headerPropertyContainer}>
                    <h1 className={styles.propertyName} onClick={() => handleClick('property name')}>{ children }</h1>
                    <h1 className={styles.poweredByText} onClick={() => handleClick('powered by Rentline')}>Powered by Rentline</h1>
                </div>
                <div className={styles.headerLogoContainer}>
                    <img className={styles.logo} alt="logo" src={logo} onClick={() => handleClick('page header logo')}/>
                    <img className={styles.poweredbylogo} alt="Powered by Rentline" src={poweredByLogo} onClick={() => handleClick('powered by Rentline')} />
                </div>
            </div>
        </div>
    );
}

export default PageHeader;