import React from 'react';
import styles from './Header.module.css';
import cn from 'classnames';
import RadioTraditionalOn from '../../../Assets/Images/SVG/radio_traditional_selected.svg';
import RadioTraditionalOff from '../../../Assets/Images/SVG/radio_traditional_deselected.svg';
import RadioRentlineOff from '../../../Assets/Images/SVG/radio_traditional_deselected.svg';
import RadioRentlineOn from '../../../Assets/Images/SVG/radio_rentline_selected.svg';

const Header = ({ onChoose, choice, choiceDisabled }) => {

    function handleSelection(selection) {
        onChoose(selection);
    }

    return (<>
        <div className={styles.callout_flexbox_row}>
            <div className={styles.empty_cell}></div>
            <div className={styles.call_out_box}>
                <div className={styles.text_block_4}>Most Popular</div>
            </div>
        </div>
        <div className={styles.heading_flexbox_row}>
            <div className={cn(styles.empty_heading_left, styles.flex_size_left)}></div>
            <div id="choice-traditional" onClick={() => !choiceDisabled && handleSelection('left')} className={styles.heading_radio_cell_traditional} style={ choiceDisabled && {height: '65px'}}>
                <div className={styles.test_radio_div}>
                    <label className={cn(styles.test_radio, styles.w_radio)}>
                        <span className={cn(styles.test_radio_label_traditional, styles.w_form_label)}>Pre-paid <br />Deposit</span>
                    </label>
                    { !choiceDisabled && <div className={styles.radio_div_holder}>
                        { 
                            choice === 'left' ? 
                            <img src={RadioTraditionalOn} alt="" />
                            :
                            <img src={RadioTraditionalOff} alt="" />
                        }
                    </div> }
                </div>
            </div>
            <div id="choice-rentline" onClick={() => !choiceDisabled && handleSelection('right')} className={styles.heading_radio_cell_rentline} style={ choiceDisabled && {height: '65px'}}>
                <div className={styles.test_radio_div}>
                    <label className={cn(styles.test_radio, styles.w_radio)}>
                        <span className={cn(styles.test_radio_rentline, styles.w_form_label)}>No <br />Deposit</span>
                    </label>
                    { !choiceDisabled && <div className={styles.radio_div_holder}>
                        {
                            choice === 'right' ?
                            <img src={RadioRentlineOn} alt=""/>
                            :
                            <img src={RadioRentlineOff} alt=""/>
                        }
                    </div> }
                </div>
            </div>
        </div>
    </>);
}

export default Header;