import React, { useState, useEffect } from 'react';
import Input from '../Fields/Input'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';

const UnitForm = ({
    onBedroomChanged, 
    onBathroomChanged, 
    onUnitNumberChanged, 
    onSquareFootageChanged, 
    onAvailableDateChanged, 
    unit,
    error}) => {
    const [selectedDate, setSelectedDate] = useState(moment(unit.availableDate));

    useEffect(() => {
        // onAvailableDateChanged(selectedDate);
    });

    function handleDateChange(date) {
        onAvailableDateChanged(date);
        setSelectedDate(date);        
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Input label="Unit Number" width="40%" autoFocus value={unit.number} onChange={onUnitNumberChanged} error={error.unit}/>
            <Input label="Square Footage" width="40%" type="number" value={unit.squareFootage} onChange={onSquareFootageChanged} error={error.sqft}/><br />
            <Input label="Bedrooms" required width="40%" type="number" value={unit.bedrooms} onChange={onBedroomChanged} error={error.bed}/>
            <Input label="Bathrooms" required width="40%" type="number" value={unit.bathrooms} onChange={onBathroomChanged} error={error.bath}/><br />
            <DatePicker label="Available" format={'YYYY-MM-DD'} style={{marginTop: '16px', width: '40%'}} inputVariant='outlined' value={selectedDate} onChange={handleDateChange} />
        </MuiPickersUtilsProvider>
    );
}

export default UnitForm;