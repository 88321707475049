import React, { useContext } from 'react';
import { MixpanelContext } from '../../Mixpanel';
import Link from 'react-plaid-link';
import styles from './Link.module.css';
import { ENVS } from '../../Constants';

const PlaidLink = ({ children, onExit, onSuccess, onClick, mini }) => {
    const env = getPlaidEnv();
    const publicKey = "4ee510ae1b43c13b4e0855a551e8dc";
    const mixpanel = useContext(MixpanelContext);
    const className = mini ? styles.mini : styles.primary;

    function onEvent(eventName, metadata) {
        metadata.event_name = eventName;
        mixpanel.track("Plaid Event", metadata);
    }

    return (
        <Link
            clientName="Rentline"
            className={className}
            style={{}}
            env={env}
            product={["auth", "transactions"]}
            publicKey={publicKey}
            onExit={onExit}
            onSuccess={onSuccess}
            onClick={onClick}
            onEvent={onEvent}>
                {mini ? <div className={styles.add}>
                    <svg className={styles.addIcon} focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                        <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path>
                        <path fill="none" d="M0 0h24v24H0z"></path>
                    </svg>
                </div> : null}
                <div className={styles.buttonText}>{children}</div>
        </Link>
    );
}

export default PlaidLink;

function getPlaidEnv() {
    switch(process.env.REACT_APP_RENTLINE_APP_ENV) {
        case ENVS.DEMO: 
            return "sandbox";        
        case ENVS.LOCAL:
            return "sandbox";
        case ENVS.DEV:
            return "sandbox";
        case ENVS.STAGING:
            return "development";
        case ENVS.PROD:
            return "production";
        default:
            return "sandbox";
    }
}